import React,{useEffect,useCallback, useState} from 'react'
import { appUrl } from '../../config/constant'
import { Link,useParams } from 'react-router-dom'
import { FileRoutes } from '../../config/RouteConfig'
import { AuthHeader, toastError, toastSuccess } from '../../services/CommonFunction'
import { callApi } from '../../services/ApiService'
import { GetFilesOfClientByIdApi, GetFileStagesApi } from '../../config/api'
import classNames from "classnames"
import FileDetail from './FileDetailsComponent/FileDetail'
import RequiredDocumentList from './FileDetailsComponent/RequiredDocumentList'
import SendMessageToClient from './FileDetailsComponent/SendMessageToClient'
import PlotValidationCertificate from './FileDetailsComponent/PlotValidationCertificate'
import ENagarPortal from './FileDetailsComponent/ENagarPortal'
import OwnerPortal from './FileDetailsComponent/OwnerPortal'
import DocumentUpload from './FileDetailsComponent/DocumentUpload'
import Road_Deposit_Security_Fees from './FileDetailsComponent/Road_Deposit_Security_Fees'
import Assistant_Junior_Engineer from './FileDetailsComponent/Assistant_Junior_Engineer'
import Deputy_engineer from './FileDetailsComponent/Deputy_engineer'
import Zonal_officer_component from './FileDetailsComponent/Zonal_officer_component'
import Deputy_commissioner from './FileDetailsComponent/Deputy_commissioner'
import Commissioner from './FileDetailsComponent/Commissioner'
import All_important_fees from './FileDetailsComponent/All_important_fees'
import FS_IC_Aminetes_fees from './FileDetailsComponent/FS_IC_Aminetes_fees'
import Work_fee from './FileDetailsComponent/Work_fee'
import Approved_plan from './FileDetailsComponent/Approved_plan'
import File_approved_reject from './FileDetailsComponent/File_approved_reject'

//Details for files
const Details = () => {

   const params = useParams();
   const [stageList,setStageList] = useState([]);
   const [currentState,setCurrentState] = useState(1);
   const [lastStage,setLastStage] = useState(1);
   const [file,setFile] = useState();
   const [loading,setLoading] = useState(false);

   const fetchStage = useCallback(async ()=>{
      try
      {
         const res = await callApi(GetFileStagesApi.method,GetFileStagesApi.url,params?.id,null,AuthHeader());

         if(!res?.data?.error)
         {
            setStageList(res?.data?.data);
         }
        
      }
      catch(err)
      {
         console.log(err);
         toastError("Something went wrong");
      }
   },[]);

   const fetchFile = useCallback(async ()=>{
      try
      {
         const res = await callApi(GetFilesOfClientByIdApi.method,GetFilesOfClientByIdApi.url,params?.id,null,AuthHeader());

         if(!res?.data?.error)
         {
            if(Array.isArray(res?.data?.data))
            {
               setFile(res?.data?.data[0]);
               setLastStage((prevState)=>res?.data?.data[0]?.mst_stage_id);
               if(res?.data?.data[0]?.mst_stage_id==2 || res?.data?.data[0]?.mst_stage_id==3)
               {
                  renderComponent(1);
               }
               else if(res?.data?.data[0]?.mst_stage_id>=9 && res?.data?.data[0]?.mst_stage_id<=13)
               {
                  renderComponent(8);
               }
               else if(res?.data?.data[0]?.mst_stage_id==18)
               {
                  renderComponent(17);
               }
               else
               {
                  renderComponent(res?.data?.data[0]?.mst_stage_id);
               }
            }
         }
        
      }
      catch(err)
      {
         console.log(err);
         toastError("Something went wrong");
      }
   },[]);

   const renderComponent = (num) =>{
      setCurrentState(num);
   }

   const UpdateStage = (num) =>{
      console.log("Stage id : "+num);
      setLastStage(num);
      renderComponent(num);
   }

   const pStyle = {
      color : "green"
   }

   useEffect(()=>{
      fetchStage();
      fetchFile();
   },[]);

  return (
    <React.Fragment>
      <section className="breadcrumb_section">
         <div className="container">
            <nav aria-label="breadcrumb">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={`/${FileRoutes.previewFile}`} style={{color:"black"}}>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">File Details</li>
               </ol>
            </nav>
         </div>
      </section>
      <section className="amalgamation">
         <div className="container">
            <div className="amalgamation_heading">
               <h2>{file?.file_name}</h2>
               <h5>{file?.office_file_no}</h5>
            </div>
            <div className="row">
               <div className="col-xl-4 col-md-4 col-12">
                  <div className="sidebar nav nav-tabs" id="myTab" role="tablist">
                     {stageList?.length>0 ?
                     <React.Fragment>
                        {stageList.map((s,i)=>(
                           <>
                           {s?.id<=lastStage? 
                              <p onClick={()=>renderComponent(s?.id)} className={classNames(`amalgamation_sidebar_link`,{"active":s?.id==(currentState)})} type="button" role="tab" style={s?.id==file?.mst_stage_id ? pStyle : {}}><span className="number">{i+1}</span><span className="link_text">{s?.title}</span></p>
                              :
                              <p onClick={null} className={classNames(`amalgamation_sidebar_link`,{"active":s?.id==(currentState)})} type="button" role="tab" style={s?.id==file?.mst_stage_id ? pStyle : {}}><span className="number">{i+1}</span><span className="link_text">{s?.title}</span></p>
                           }
                           </>
                        ))}
                     </React.Fragment>
                     :""}
                  </div>
               </div>
               <div className="col-xl-8 col-md-8 col-12">
                  <div className="content_box">
                  <div className="tab-content" id="myTabContent">
                     {(()=>{
                        switch(currentState)
                        {
                           case 1 : return (<FileDetail/>)
                           case 2 : return (<RequiredDocumentList/>)
                           case 3 : return (<SendMessageToClient/>)
                           case 4 : return (<PlotValidationCertificate/>)
                           case 5 : return (<ENagarPortal/>)
                           case 6 : return (<OwnerPortal/>)
                           case 7 : return (<DocumentUpload/>)
                           case 8 : return (<Road_Deposit_Security_Fees/>)
                           case 9 : return (<Assistant_Junior_Engineer/>)
                           case 10 : return (<Deputy_engineer/>)
                           case 11: return (<Zonal_officer_component/>)
                           case 12: return (<Deputy_commissioner/>)
                           case 13: return (<Commissioner/>)
                           case 14: return (<All_important_fees/>)
                           case 15: return (<FS_IC_Aminetes_fees/>)
                           case 16: return (<Work_fee/>)
                           case 17: return (<Approved_plan onUpdateStage={(newStage)=>UpdateStage(newStage)}/>)
                           case 18: return (<File_approved_reject/>)
                           default : return (<FileDetail/>)
                        }
                     })()}
                  </div>

                     
                  </div>
               </div>
            </div>
         </div>
      </section>
 

    </React.Fragment>
  )
}

export default Details