import React, { useState, useEffect } from 'react'
import { GetAssistantJuniorEngineer, GetCommissioner, GetDeputyCommissioner, GetDeputyEngineer, GetDocumentUpload, GetEnagarPortal, GetFileDetails, GetFSIC_Aminities, GetPlotValidation, GetZonalOfficer } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError, validateJsonString } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';
import { urls,appUrl } from '../../../config/constant';

const FS_IC_Aminetes_fees = () => {

  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [data,setData] = useState();

  const [icRows,setIcRows] = useState([]);
  const [fsiRows,setFsiRows] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await callApi(GetFSIC_Aminities.method, GetFSIC_Aminities.url, params?.id, null, AuthHeader());

      if (!res?.data?.error) {
        setLoading(false);
        const payload = res?.data?.data;

        if (Array.isArray(payload)) 
        {
          const data1 = {
            fsi_total_amount: payload[0]?.amount != null ? payload[0]?.amount : "",
            fsi_total_emi: payload[0]?.emi != null ? payload[0]?.emi : "",
            is_type: payload[0]?.is_type,
            emi_data: validateJsonString(payload[0]?.emi_data)
          }

          const data2 = {
            total_ammount: payload[1]?.amount != null ? payload[1]?.amount : "",
            total_emi: payload[1]?.emi != null ? payload[1]?.emi : "",
            is_type: payload[1]?.is_type,
            emi_data: validateJsonString(payload[1]?.emi_data)
          }

          if (Array.isArray(data1?.emi_data)) {
            setFsiRows(data1?.emi_data);
          }

          if (Array.isArray(data2?.emi_data)) {
            setIcRows(data2?.emi_data);
          }

          setData({
            fsi_total_amount: data1?.fsi_total_amount,
            fsi_total_emi: data1?.fsi_total_emi,
            total_amount: data2?.total_ammount,
            total_emi: data2?.total_emi,
          });

        }

      }
      else {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch (err) {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

    const downloadFile = (
    filePath,
    fileName = filePath,
  ) => {
    
    fetch('https://cors-anywhere.herokuapp.com/https://webapp.zaidexceldesign.com/public/'+filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    }

  return (
    <div>
      <div className="content_box_header">
        <h2>F.S./I.C. - Amineties Fees</h2>
      </div>
      <div className="content_box_body">
         {!loading ? 

          <div>

          <div>
            <h5>F.S.I ( Residential/Commercial (Sq.Mt.))</h5>

            <div className='row'>
              <div className="col">
                <span>F.S.I Total amount : {data?.fsi_total_amount}</span>
              </div>
              <div className="col">
                <span>F.S.I Total Emi : {data?.fsi_total_emi}</span>
              </div>
              
            </div>

            <table>
              <thead>
                <tr>
                  <th style={{width:"10%"}}>No.</th>
                  <th style={{width:"20%"}}>Date</th>
                  <th style={{width:"10%"}}>Amount</th>
                  <th style={{width:"20%"}}>Received Date</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
              {fsiRows.map((e,i)=>(
                <React.Fragment>
                 {e?.show=='1' ?
                <tr>
                  <td>({i+1})</td>
                  <td>{e?.date}</td>
                  <td>{e?.amount}</td>
                  <td>{e?.received_date}</td>
                  <td>{e?.document_path?.length>0 || e?.document_path!=null ?
                  <button onClick={() => downloadFile(e?.document_path)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button> : ""}</td>
                   {/* <a href={`${urls.dir_url}/${e?.document_path}`}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td> */}
                </tr>
                :""}
                </React.Fragment>
              ))}
              </tbody>
            </table>

          </div>
            <hr/>

          <div>
              <h5>I.C. Deposite/Aminitees Fees {"(Sq.Mt.(As Per Zone)"}</h5>
              <div className='row'>
              <div className="col">
                <span>F.S.I Total amount : {data?.fsi_total_amount}</span>
              </div>
              <div className="col">
                <span>F.S.I Total Emi : {data?.fsi_total_emi}</span>
              </div>
              
            </div>

            <table>
              <thead>
                <tr>
                  <th style={{width:"10%"}}>No.</th>
                  <th style={{width:"20%"}}>Date</th>
                  <th style={{width:"10%"}}>Amount</th>
                  <th style={{width:"20%"}}>Received Date</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
              {icRows.map((e,i)=>(
                <React.Fragment>
                  {e?.show=='1' ?
                <tr>
                  <td>({i+1})</td>
                  <td>{e?.date}</td>
                  <td>{e?.amount}</td>
                  <td>{e?.received_date}</td>
                  <td>{e?.document_path?.length>0 || e?.document_path!=null ?
                   <button onClick={() => downloadFile(e?.document_path)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button> : ""}</td>
                   {/* <a href={`${urls.dir_url}/${e?.document_path}`}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a> : ""}</td> */}
                </tr>
                :""}
                </React.Fragment>
              ))}
              </tbody>
            </table>
          </div>
          
          </div>

        :
        <DisplayLoader value={false}/>}
      </div>
    </div>
  )
}

export default FS_IC_Aminetes_fees