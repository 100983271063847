import React,{useState,useEffect} from 'react'
import { GetEnagarPortal, GetFileDetails, GetPlotValidation } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';



const ENagarPortal = () => {

  const params = useParams();

  const [loading,setLoading] = useState(false);

  const [formAccess, setFormAccess] = useState({
      type1: {
          is_save: 0
      },
      type2: {
          is_save: 0
      },
      type3: {
          is_save: 0
      },
      type4: {
          is_save: 0
      },
      type5: {
          is_save: 0
      },
      type6: {
          is_save: 0
      }
  });

  const [data,setData] = useState([]);
  
  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetEnagarPortal.method,GetEnagarPortal.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        const data1 = res.data.data1[0];
        const data2 = res.data.data2[0];

        if (res.data.maxvalue > 0) {
          const data2_type_save = JSON.parse(data2.type_save);
          setFormAccess(data2_type_save);


              setData({
                  drawing_assign_date: data2_type_save.type1.is_save ? sanitizeDate(data2.drawing_assign_date) : sanitizeDate(data1.drawing_assign_date),
                  completed_date: data2_type_save.type1.is_save ? sanitizeDate(data2.completed_date) : sanitizeDate(data1.completed_date),
                  // assign_planner: data2_type_save.type1.is_save ? data2.assign_planner : data1.assign_planner,

                  completed_date2: data2_type_save.type2.is_save ? sanitizeDate(data2.completed_date2) : sanitizeDate(data1.completed_date2),
                  // assign_liasoner: data2_type_save.type2.is_save ? data2.assign_liasoner : data1.assign_liasoner,

                  // login: data2_type_save.type3.is_save ? data2.login : data1.login,
                  // password: data2_type_save.type3.is_save ? data2.password : data1.password,
                  application_number: data2_type_save.type3.is_save ? data2.application_number : data1.application_number,
                  // assign_application_number: data2_type_save.type3.is_save ? data2.assign_application_number : data1.assign_application_number,
                  upload_date: data2_type_save.type3.is_save ? sanitizeDate(data2.upload_date) : sanitizeDate(data1.upload_date),

                  pdf_received_date: data2_type_save.type4.is_save ? sanitizeDate(data2.pdf_received_date) : sanitizeDate(data1.pdf_received_date),
                  // assign_pdf_received_date: data2_type_save.type4.is_save ? data2.assign_pdf_received_date : data1.assign_pdf_received_date,

                  smc_checking_date: data2_type_save.type5.is_save ? sanitizeDate(data2.smc_checking_date) : sanitizeDate(data1.smc_checking_date),

                  // assign_smc_checking_date: data2_type_save.type5.is_save ? data2.assign_smc_checking_date : data1.assign_smc_checking_date,

                  approve_date: data2_type_save.type6.is_save ? sanitizeDate(data2.approve_date) : sanitizeDate(data1.approve_date),
                  smc_upload_date: data2_type_save.type5.is_save ? sanitizeDate(data2.smc_upload_date) : sanitizeDate(data1.smc_upload_date),
                  // assign_approve_date: data2_type_save.type6.is_save ? data2.assign_approve_date : data1.assign_approve_date,

                  is_save: data2.is_save ? data2.is_save : 0,
                  type_save: data2.type_save ? data2.type_save : formAccess,
                  is_rejected: data2.is_rejected
              });

          }
          else {
              const jsonData = JSON.parse(data1.type_save)
              setFormAccess(jsonData);
              setData({
                  drawing_assign_date: data1.drawing_assign_date ? GetDate(data1.drawing_assign_date, "YYYY-MM-DD") : "-",
                  completed_date: data1.completed_date ? GetDate(data1.completed_date, "YYYY-MM-DD") : "-",
                  completed_date2: data1.completed_date2 ? GetDate(data1.completed_date2, "YYYY-MM-DD") : "-",
                  upload_date: data1.upload_date ? GetDate(data1.upload_date, "YYYY-MM-DD") : "-",
                  application_number: data1.application_number,
                  // assign_application_number: data1.assign_application_number ? data1.assign_application_number : "",
                  pdf_received_date: data1.pdf_received_date ? GetDate(data1.pdf_received_date, "YYYY-MM-DD") : "-",
                  // assign_pdf_received_date: data1.assign_pdf_received_date ? data1.assign_pdf_received_date : "",
                  smc_checking_date: data1.smc_checking_date ? GetDate(data1.smc_checking_date, "YYYY-MM-DD") : "-",
                  smc_upload_date: data1.smc_upload_date ? GetDate(data1.smc_upload_date, "YYYY-MM-DD") : "-",
                  // assign_smc_checking_date: data1.assign_smc_checking_date ? data1.assign_smc_checking_date : "",
                  approve_date: data1.approve_date ? GetDate(data1.approve_date, "YYYY-MM-DD") : "-",
                  // assign_approve_date: data1.assign_approve_date ? data1.assign_approve_date : "",
                  // assign_liasoner: data1.assign_liasoner ? data1.assign_liasoner : "",
                  // assign_planner: data1.assign_planner ? data1.assign_planner : "",
                  // login: data1.login ? data1.login : "",
                  // password: data1.password ? data1.password : "",
                  is_save: data1.is_save ? data1.is_save : 0,
                  type_save: data1.type_save ? data1.type_save : formAccess,
                  is_rejected: data1.is_rejected
              });
          }

      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);
      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  const sanitizeDate = (date) => {
    return date!=null ? GetDate(date, "DD-MM-YYYY") : "-"
  }

  useEffect(()=>{
    fetchData();
  },[]);
  
  const myStyle = {
    width: "35%"
  }

  return (
    <div>
        <div className="content_box_header">
            <h2>ENagar Portal</h2>
        </div>
        <div className="content_box_body">
            {!loading ?

            <div>
              
              <table className='table'>

                <tbody>
                  <tr>
                    <td style={myStyle}>Drawing assign date</td>
                    <td>{data?.drawing_assign_date}</td>
                  </tr>
                  {/* <tr>
                    <td style={myStyle}>Assign Predcr Planner</td>
                    <td>{data?.assign_planner}</td>
                  </tr> */}
                  <tr>
                    <td style={myStyle}>Completed date</td>
                    <td>{data?.completed_date2}</td>
                  </tr>
                  {/* <tr>
                    <td style={myStyle}>Assign Lisoner</td>
                    <td>{data?.assign_liasoner}</td>
                  </tr> */}
                  {/* <tr>
                    <td style={myStyle}>Login ID</td>
                    <td>{data?.login}</td>
                  </tr>
                  <tr>
                    <td style={myStyle}>Password</td>
                    <td>{data?.password}</td>
                  </tr> */}
                  <tr>
                    <td style={myStyle}>Application Number</td>
                    <td>{data?.application_number}</td>
                  </tr>
                  <tr>
                    <td style={myStyle}>Upload Date</td>
                    <td>{data?.application_number}</td>
                  </tr>
                  <tr>
                    <td style={myStyle}>Received Date</td>
                    <td>{data?.pdf_received_date}</td>
                  </tr>
                  <tr>
                    <td style={myStyle}>SMC Checking Date</td>
                    <td>{data?.smc_checking_date}</td>
                  </tr>
                  <tr>
                    <td style={myStyle}>SMC Upload Date</td>
                    <td>{data?.smc_upload_date}</td>
                  </tr>
                  <tr>
                    <td style={myStyle}>Approved Date</td>
                    <td>{data?.approve_date}</td>
                  </tr>
                </tbody>

              </table>

             
            </div>
            :
            <DisplayLoader value={false}/>}
        </div>
    </div>
  )
}

export default ENagarPortal