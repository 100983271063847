import React,{useState,useEffect} from 'react'
import { GetAllImportantFees, GetAssistantJuniorEngineer, GetCommissioner, GetDeputyCommissioner, GetDeputyEngineer, GetDocumentUpload, GetEnagarPortal, GetFileDetails, GetPlotValidation, GetZonalOfficer } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';
import { GetDate } from '../../../services/DateAndTimeService';
import { urls,appUrl } from '../../../config/constant';

const All_important_fees = () => {

  const params = useParams();

  const [loading,setLoading] = useState(false);

  const [data,setData] = useState([]);

  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetAllImportantFees.method,GetAllImportantFees.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        const payload = res?.data?.data;
        const rows = [];        

        payload.forEach((e,i)=>{
          rows.push({
              mst_type_id : e?.mst_type_id,
              show_website : e?.show_website,
              description:e?.description,
              amount : e?.amount!=null ?  e?.amount : "",
              date : e?.date ? GetDate(e?.date,"DD-MM-YYYY") : "",
              file:e?.file ? e?.file : "", 
          });
         })      

        setData(rows);
      }
      else
      {
        setLoading(false);
        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const myStyle = {
    width : "20%"
  }


  const downloadFile = (
    filePath,
    fileName = filePath,
  ) => {
    
    fetch('https://webclient.zaidexceldesign.com:3001/api/'+filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
    }

  return (
    <div>
        <div className="content_box_header">
            <h2>All Important Fees</h2>
        </div>
        <div className="content_box_body">
          {!loading ? 
            <table className='table'>
              <thead>
                <tr>
                  <td style={{width:"5%"}}>No.</td>
                  <td style={{width:"40%"}}>Description</td>
                  <td>Amount</td>
                  <td>Date</td>
                  <td>File</td>
                </tr>
              </thead>
              <tbody>
                {data.map((value,index)=>(
                  <tr>
                    <td>({index+1})</td>
                    <td>{value?.description}</td>
                    <td>{value?.amount}</td>
                    <td>{value?.date}</td>
                    <td>{value?.file ? 
                    <button onClick={() => downloadFile(value?.file)}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></button>
                    // <a href={`${urls.dir_url}/${value?.file}`} target={"_blank"}><img src={`${appUrl}/assets/svgs/down_arrow.svg`}/></a>
                     : ""}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot></tfoot>
            </table>  
          :
          <DisplayLoader value={false}/>}
        </div>
    </div>
  )
}

export default All_important_fees