import React,{useState,useEffect} from 'react'
import { GetFileDetails } from '../../../config/api';
import { callApi } from '../../../services/ApiService';
import { AuthHeader, toastError } from '../../../services/CommonFunction';
import { useParams } from 'react-router';
import { DisplayLoader } from '../../../UtilsComponent/DisplayLoader';

const FileDetail = () => {

  const params = useParams();

  const [file_details,setFile_details]=useState();
  const [loading,setLoading] = useState(false);

  const fetchData =async () =>{
    try
    {
      setLoading(true);
      const res = await callApi(GetFileDetails.method,GetFileDetails.url,params?.id,null,AuthHeader());

      if(!res?.data?.error)
      {
        setLoading(false);
        if(Array.isArray(res?.data?.data))
        {
          const payload = res?.data?.data[0];
          setFile_details({
            smc_case_app_id : payload?.smc_case_app_id,
            village : payload?.village,
            taluka : payload?.taluka,
            district : payload?.district,
            rs_block_no : payload?.rs_block_no,
            rs_block_no_text : payload?.rs_block_no_text,
            tika_moje_no : payload?.tika_moje_no,
            tika_moje_no_text : payload?.tika_moje_no_text,
            ward_no : payload?.ward_no,
            cs_no : payload?.cs_no,
            tps_no : payload?.tps_no,
            tps_stage : payload?.tps_stage,
            op_no : payload?.op_no,
            fp_no : payload?.fp_no,
            subplot_no : payload?.subplot_no,
            is_save : payload?.is_save,
          });
        }
        
      }
      else
      {
        setLoading(false);

        toastError(res?.data?.message);
      }

    }
    catch(err)
    {
      setLoading(false);

      console.log(err);
      toastError(err?.res?.data?.message);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  return (
    <div>
        <div className="content_box_header">
            <h2>File Details</h2>
        </div>
        <div className="content_box_body">
          {!loading ? 
        <table className="table caption-top"> 
                                <tbody>
                                    <tr>
                                       <th>SMC Case No./Application ID No</th>
                                       <td><span>:</span>{file_details?.smc_case_app_id}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>Village</th>
                                       <td><span>:</span>{file_details?.village}</td> 
                                    </tr>
                                    <tr>
                                       <th>District</th>
                                       <td><span>:</span>{file_details?.district}</td> 
                                    </tr>
                                    <tr>
                                       <th>Taluka</th>
                                       <td><span>:</span>{file_details?.taluka}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>Moje No.</th>
                                       <td><span>:</span>{file_details?.tika_moje_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>Ward No.</th>
                                       <td><span>:</span>{file_details?.ward_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>C.S No.</th>
                                       <td><span>:</span>{file_details?.cs_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>T.P.S. No.</th>
                                       <td><span>:</span>{file_details?.tps_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>F.P. No.</th>
                                       <td><span>:</span>{file_details?.fp_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>Subplot No.</th>
                                       <td><span>:</span>{file_details?.subplot_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>O.P No.</th>
                                       <td><span>:</span>{file_details?.op_no}</td>                                     
                                    </tr>
                                    <tr>
                                       <th>T. P. S. Stage</th>
                                       <td><span>:</span>{file_details?.tps_stage == 1 ? "Draft" : file_details?.tps_stage == 2 ? "Final" : file_details?.tps_stage == 3 ? "Non TP" : ""}</td>                                     
                                    </tr>
                                </tbody>
                              </table>
                              :
                              <DisplayLoader value={false}/>
          }
        </div>
    </div>
  )
}

export default FileDetail